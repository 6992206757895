@charset "UTF-8";

body, #wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

footer {
	margin-top: auto;
}

.my-div-style {
	margin-top: 6rem;
}

.navbar-custom {
	background-color: rgba(255, 0, 0, 0.1);
}

.flex{
	display: flex;
}

.menu-style {
	width: 100px;
}

.clock-style {
	font-size: xx-large;
}
